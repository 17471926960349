import { takeLatest, put } from 'redux-saga/effects';
import { $host, $authHost } from 'store/axios';
import {
  getNotificationsList,
  getNotificationsListSuccess,
  getNotificationsListFailure,
  deleteOneNotification,
  deleteOneNotificationSuccess,
  deleteOneNotificationFailure,
  deleteAllNotification,
  deleteAllNotificationSuccess,
  deleteAllNotificationFailure,
  readOneNotifiaction,
  readOneNotifiactionSuccess,
  readOneNotifiactionFailure,
  readAllNotifiaction,
  readAllNotifiactionSuccess,
  readAllNotifiactionFailure,
} from './notificationSlice';

function* getNotifications(action) {
  const { payload } = action;
  try {
    const response = yield $authHost.get(`/notifications/business/${payload}`);
    yield put(getNotificationsListSuccess(response.data.data.notifications));
    // yield put(
    //   getNotificationsListSuccess([
    //     {
    //       id: 1,
    //       title: 'Booking Confirmation',
    //       description: 'Your booking has been confirmed for January 20, 2025.',
    //       user_id: 101,
    //       business_id: 202,
    //       order_id: 303,
    //       notification_type: 'Booking',
    //       read: false,
    //     },
    //     {
    //       id: 2,
    //       title: 'Payment Successful',
    //       description: 'Your payment of £150.00 has been successfully processed.',
    //       user_id: 101,
    //       business_id: 202,
    //       order_id: 304,
    //       notification_type: 'Payment',
    //       read: true,
    //     },
    //     {
    //       id: 3,
    //       title: 'Specialist Availability',
    //       description: 'A specialist is now available for your requested service.',
    //       user_id: 102,
    //       business_id: 203,
    //       order_id: 305,
    //       notification_type: 'Availability',
    //       read: false,
    //     },
    //     {
    //       id: 4,
    //       title: 'Review Request',
    //       description: 'Please review your recent service experience with us.',
    //       user_id: 103,
    //       business_id: 204,
    //       order_id: 306,
    //       notification_type: 'Review',
    //       read: false,
    //     },
    //     {
    //       id: 5,
    //       title: 'Discount Offer',
    //       description:
    //         'Enjoy a 20% discount on your next service. Offer valid until January 31, 2025.',
    //       user_id: 104,
    //       business_id: 205,
    //       order_id: null,
    //       notification_type: 'Offer',
    //       read: true,
    //     },
    //   ]),
    // );
  } catch (error) {
    if (error.response?.code === 500) {
      const errorPreview = {
        code: 500,
        message: error.message,
        description: error.description,
      };
      yield put(getNotificationsListFailure(errorPreview));
    }
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      yield put(getNotificationsListFailure(errorPreview));
    }
  }
}

function* deleteNotificationOne(action) {
  const { payload } = action;
  try {
    const response = yield $authHost.delete(`/notifications/delete/${payload}`);
    if (response.data.code === 200) {
      const success = { message: response.data.message, description: response.data.description };
      yield put(deleteOneNotificationSuccess(success));
    }
  } catch (error) {
    if (error.response?.code === 500) {
      const errorPreview = {
        code: 500,
        message: error.message,
        description: error.description,
      };
      yield put(deleteOneNotificationFailure(errorPreview));
    }
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      yield put(deleteOneNotificationFailure(errorPreview));
    }
  }
}

function* deleteNotificationAll({ payload }) {
  try {
    const response = yield $authHost.delete(`/notifications/delete-all/business/${payload}`);
    if (response.data.code === 200) {
      const success = { message: response.data.message, description: response.data.description };
      yield put(deleteAllNotificationSuccess(success));
    }
  } catch (error) {
    if (error.response?.code === 500) {
      const errorPreview = {
        code: 500,
        message: error.message,
        description: error.description,
      };
      yield put(deleteAllNotificationFailure(errorPreview));
    }
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      yield put(deleteAllNotificationFailure(errorPreview));
    }
  }
}

function* readNotificationOne(action) {
  const { payload } = action;
  try {
    const response = yield $authHost.patch(`/notifications/read/${payload}`);
    if (response.data.code === 200) {
      const success = { message: response.data.message, description: response.data.description };
      yield put(readOneNotifiactionSuccess(success));
    }
  } catch (error) {
    if (error.response?.code === 500) {
      const errorPreview = {
        code: 500,
        message: error.message,
        description: error.description,
      };
      yield put(readOneNotifiactionFailure(errorPreview));
    }
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      yield put(readOneNotifiactionFailure(errorPreview));
    }
  }
}

function* readNotificationAll({ payload }) {
  try {
    const response = yield $authHost.patch(`/notifications/read-all/business/${payload}`);
    if (response.data.code === 200) {
      const success = { message: response.data.message, description: response.data.description };
      yield put(readAllNotifiactionSuccess(success));
    }
  } catch (error) {
    if (error.response?.code === 500) {
      const errorPreview = {
        code: 500,
        message: error.message,
        description: error.description,
      };
      yield put(readAllNotifiactionFailure(errorPreview));
    }
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      yield put(readAllNotifiactionFailure(errorPreview));
    }
  }
}

export default function* notificationSaaga() {
  yield takeLatest(getNotificationsList.type, getNotifications);
  yield takeLatest(deleteOneNotification.type, deleteNotificationOne);
  yield takeLatest(deleteAllNotification.type, deleteNotificationAll);
  yield takeLatest(readOneNotifiaction.type, readNotificationOne);
  yield takeLatest(readAllNotifiaction.type, readNotificationAll);
}
