import { takeLatest, put, call } from 'redux-saga/effects';
import { $authHost } from 'store/axios';
import {
  getBookingsList,
  getBookingsListSuccess,
  getBookingsListFailure,
  getBookingsDetail,
  getBookingsDetailSuccess,
  acceptService,
  acceptServiceSuccess,
  acceptServiceFailure,
  acceptBooking,
  acceptBookingSuccess,
  acceptBookingFailure,
  cancelService,
  cancelServiceSuccess,
  cancelServiceFailure,
  cancelBooking,
  cancelBookingSuccess,
  cancelBookingFailure,
  completeBooking,
  completeBookingSuccess,
  markNoShow,
  markNoShowSuccess,
  markNoShowFailure,
  assignSpecialist,
  assignSpecialistSuccess,
} from './dashboardSlice';

function* getAllBookingsList(action) {
  const { payload } = action;
  try {
    const response = yield call($authHost.get, `/booking/business-bookings/${payload}`);
    if (response.data.code === 200) {
      yield put(getBookingsListSuccess(response.data.data.business_bookings));
    }
  } catch (error) {
    yield put(getBookingsListFailure(error.response?.data || error.message));
  }
}

// booking Details

function* getBookingDetailsSaga(action) {
  const { business_id, booking_id } = action.payload;
  try {
    const response = yield call(
      $authHost.get,
      `/booking/business-booking/${business_id}/${booking_id}`,
    );
    if (response.data.code === 200) {
      yield put(getBookingsDetailSuccess(response.data.data.business_bookings[0]));
    }
  } catch (error) {
    yield put(getBookingsListFailure(error.response?.data || error.message));
  }
}

// Accept a service by business
function* acceptServiceSaga(action) {
  const { booking_id, service_id } = action.payload;
  try {
    const response = yield call($authHost.post, '/booking/accept-service-by-business', {
      booking_id,
      service_id,
    });
    if (response.data.code === 200) {
      yield put(acceptServiceSuccess('Service accepted successfully.'));
    }
  } catch (error) {
    yield put(acceptServiceFailure(error.response?.data || error.message));
  }
}

// Accept a booking by business
function* acceptBookingSaga(action) {
  const { booking_id } = action.payload;
  try {
    const response = yield call($authHost.post, '/booking/accept-booking-by-business', {
      booking_id,
    });
    if (response.data.code === 200) {
      yield put(acceptBookingSuccess('Booking accepted successfully.'));
    }
  } catch (error) {
    yield put(acceptBookingFailure(error.response?.data || error.message));
  }
}

// Cancel a service by business
function* cancelServiceSaga(action) {
  const { booking_id, service_id, reason, is_all_services_cancelled } = action.payload;
  try {
    const response = yield call($authHost.post, '/booking/cancel-service-by-business', {
      booking_id,
      service_id,
      reason,
      is_all_services_cancelled
    });

    if (response.data.code === 200) {
      yield put(cancelServiceSuccess('Service canceled successfully.'));
    }
  } catch (error) {
    yield put(cancelServiceFailure(error.response?.data || error.message));
  }
}

// Cancel a booking by business
function* cancelBookingSaga(action) {
  const { booking_id } = action.payload;
  try {
    const response = yield call($authHost.put, `/booking/cancel-order-by-business/${booking_id}`);
    if (response.data.code === 200) {
      yield put(cancelBookingSuccess('Booking canceled successfully.'));
    }
  } catch (error) {
    yield put(cancelBookingFailure(error.response?.data || error.message));
  }
}

// Complete booking
function* completeBookingSaga(action) {
  const { booking_id } = action.payload;
  try {
    const response = yield call($authHost.put, `/booking/complete-order-by-business/${booking_id}`);
    if (response.data.code === 200) {
      yield put(completeBookingSuccess('Booking completed successfully.'));
    }
  } catch (error) {
    yield put(cancelBookingFailure(error.response?.data || error.message));
  }
}

// Mark booking as No Show
function* markNoShowSaga(action) {
  const { booking_id } = action.payload;
  try {
    const response = yield call($authHost.put, `/booking/noShow-order-by-business/${booking_id}`);
    if (response.data.code === 200) {
      yield put(markNoShowSuccess('Booking marked as No Show.'));
    }
  } catch (error) {
    yield put(markNoShowFailure(error.response?.data || error.message));
  }
}

// assign specialist to service
function* assignSepecialistToAService(action) {
  try {
    const response = yield call(
      $authHost.put,
      `/booking/assign-specialist-to-service`,
      action.payload,
    );
    if (response.data.code === 200) {
      yield put(assignSpecialistSuccess(response.data.message));
    }
  } catch (error) {
    yield put(markNoShowFailure(error.response?.data || error.message));
  }
}

export default function* dashboardSaga() {
  yield takeLatest(getBookingsList.type, getAllBookingsList);
  yield takeLatest(getBookingsDetail.type, getBookingDetailsSaga);
  yield takeLatest(acceptService.type, acceptServiceSaga);
  yield takeLatest(acceptBooking.type, acceptBookingSaga);
  yield takeLatest(cancelService.type, cancelServiceSaga);
  yield takeLatest(cancelBooking.type, cancelBookingSaga);
  yield takeLatest(markNoShow.type, markNoShowSaga);
  yield takeLatest(completeBooking.type, completeBookingSaga);
  yield takeLatest(assignSpecialist.type, assignSepecialistToAService);
}
