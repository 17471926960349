import { all } from 'redux-saga/effects';
import authSaga from './auth/authSaga';
import messagesSaga from './chat/chatSaga';
import settingsSaga from './settings/settingsSaga';
import commonSaga from './commons/commonSaga';
import profileSaga from './profile/profileSaga';
import specialistsSaga from './specialists/specialistsSaga';
import servicesSaga from './services/serviceSaga';
import forgotPwdSaga from './forgotpwd/forgotpwdSaga';
import notificationSaga from './notification/notificationSaga';
import dashboardSaga from './dashboard/dashboardSaga';
import beneficiariesSaga from './beneficiaries/beneficiariesSaga';
import specialistScheduleSaga from './specialistsSchedule/specialistsScheduleSaga';
import businessScheduleSaga from './businessSchedule/businessScheduleSaga';
import bookingScheduleSaga from './bookingReschedule/bookingRescheduleSaga';
import addCompanyPersonsRootSaga from './addCompanyPersons/addCompanyPersonsSaga';
import businessServiceTypeSaga from './businessServiceType/businessServiceTypeSaga';
import promotionSaga from './promotions/promotionsSaga';
export default function* rootSaga() {
  yield all([
    authSaga(),
    settingsSaga(),
    profileSaga(),
    commonSaga(),
    specialistsSaga(),
    notificationSaga(),
    servicesSaga(),
    forgotPwdSaga(),
    dashboardSaga(),
    beneficiariesSaga(),
    specialistScheduleSaga(),
    businessScheduleSaga(),
    bookingScheduleSaga(),
    addCompanyPersonsRootSaga(),
    businessServiceTypeSaga(),
    messagesSaga(),
    promotionSaga(),
  ]);
}
