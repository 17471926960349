import { call, put, takeLatest } from 'redux-saga/effects';
import {
  createPromotionStart,
  createPromotionSuccess,
  createPromotionFailure,
  getPromotionsStart,
  getPromotionsSuccess,
  getPromotionsFailure,
  updatePromotionStart,
  updatePromotionSuccess,
  updatePromotionFailure,
  deletePromotionStart,
  deletePromotionSuccess,
  deletePromotionFailure,
} from './promotionsSlice';
import { $authHost, $host } from 'store/axios';

// Create Promotion
function* createPromotion(action) {
  try {
    const response = yield call($authHost.post, '/promotions', action.payload);
    yield put(createPromotionSuccess(response.data.data));
  } catch (error) {
    yield put(createPromotionFailure(error.response.data.message));
  }
}

// Get Promotions for a business
function* getPromotions(action) {
  try {
    const response = yield call(
      $authHost.get,
      `/promotions/${action.payload}`
    );
    yield put(getPromotionsSuccess(response.data.data.business_promotions));
  } catch (error) {
    yield put(getPromotionsFailure(error.response.data.message));
  }
}

// Update Promotion
function* updatePromotion(action) {
  try {
    const response = yield call(
      $authHost.put,
      `/promotions/${action.payload.id}`,
      action.payload.data
    );
    yield put(updatePromotionSuccess(response.data.data));
  } catch (error) {
    yield put(updatePromotionFailure(error.response.data.message));
  }
}

// Delete Promotion
function* deletePromotion(action) {
  try {
    yield call($authHost.delete, `/promotions/${action.payload.promoId}`);
    yield put(deletePromotionSuccess(action.payload));
    yield put(getPromotionsStart(action.payload.businessId))
  } catch (error) {
    yield put(deletePromotionFailure(error.response.data.message));
  }
}

export default function* promotionSaga() {
  yield takeLatest(createPromotionStart.type, createPromotion);
  yield takeLatest(getPromotionsStart.type, getPromotions);
  yield takeLatest(updatePromotionStart.type, updatePromotion);
  yield takeLatest(deletePromotionStart.type, deletePromotion);
}
