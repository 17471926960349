import { createSlice } from '@reduxjs/toolkit';

const promotionSlice = createSlice({
  name: 'promotions',
  initialState: {
    promotions: [],
    loading: false,
    error: null,
    success: null,
  },
  reducers: {
    // Create Promotion
    createPromotionStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    createPromotionSuccess: (state, action) => {
      state.loading = false;
      state.promotions.push(action.payload);
      state.success = 'Promotion created successfully';
    },
    createPromotionFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    // Get All Promotions for a business
    getPromotionsStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    getPromotionsSuccess: (state, action) => {
      state.loading = false;
      state.promotions = action.payload;
    },
    getPromotionsFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    // Update Promotion
    updatePromotionStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    updatePromotionSuccess: (state, action) => {
      state.loading = false;
      state.success = 'Promotion updated successfully';
    },
    updatePromotionFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    // Delete Promotion
    deletePromotionStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    deletePromotionSuccess: (state, action) => {
      state.loading = false;
      state.success = 'Promotion deleted successfully';
    },
    deletePromotionFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    resetPromotionStates: (state, action) => {
      state.loading = false;
      state.error = null;
      state.success = null;
    },
  },
});

export const {
  createPromotionStart,
  createPromotionSuccess,
  createPromotionFailure,
  getPromotionsStart,
  getPromotionsSuccess,
  getPromotionsFailure,
  updatePromotionStart,
  updatePromotionSuccess,
  updatePromotionFailure,
  deletePromotionStart,
  deletePromotionSuccess,
  deletePromotionFailure,
  resetPromotionStates,
} = promotionSlice.actions;

export default promotionSlice.reducer;
