import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  success: null,
  error: null,
  bookingSchedules: [],
  serviceSchedule: {},
  specialistSchedule: [],
  availableServiceSlots: [],
};

const bookingReschedule = createSlice({
  name: 'bookingReschedule',
  initialState,
  reducers: {
    getServiceSchedule: (state) => {
      state.loading = true;
    },
    getServiceScheduleSuccess: (state, action) => {
      state.loading = false;
      state.serviceSchedule = action.payload;
    },
    getServiceScheduleError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    // specialist booked schedule
    getSpecialistSchedule: (state) => {
      state.loading = true;
    },
    getSpecialistScheduleSuccess: (state, action) => {
      state.loading = false;
      state.specialistSchedule = action.payload;
    },
    getSpecialistScheduleError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    updateServiceSchedule: (state) => {
      state.loading = true;
    },
    updateServiceScheduleSuccess: (state, action) => {
      state.loading = false;
      state.success = action.payload;
    },
    updateServiceScheduleError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    // booking
    updateBookingSchedule: (state) => {
      state.loading = true;
    },
    updateBookingScheduleSuccess: (state, action) => {
      state.loading = false;
      state.success = action.payload;
    },
    updateBookingScheduleError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    getAllBookingSchedules: (state) => {
      state.loading = true;
    },
    getAllBookingSchedulesSuccess: (state, action) => {
      state.loading = false;
      state.bookingSchedules = action.payload;
    },
    getAllBookingSchedulesError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    // available slots
    getAvailableSlots: (state) => {
      state.loading = true;
    },
    getAvailableSlotsSuccess: (state, action) => {
      state.loading = false;
      state.availableServiceSlots = action.payload;
    },
    getAvailableSlotsError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    resetBookingScheduleState: (state) => {
      state.loading = false;
      state.success = null;
      state.error = null;
      state.bookingSchedules = [];
    },
  },
});

export const {
  getServiceSchedule,
  getServiceScheduleSuccess,
  getServiceScheduleError,
  //
  getSpecialistSchedule,
  getSpecialistScheduleSuccess,
  getSpecialistScheduleError,
  //
  updateServiceSchedule,
  updateServiceScheduleSuccess,
  updateServiceScheduleError,
  // booking
  updateBookingSchedule,
  updateBookingScheduleSuccess,
  updateBookingScheduleError,
  getAllBookingSchedules,
  getAllBookingSchedulesSuccess,
  getAllBookingSchedulesError,
  // availableServiceSlots
  getAvailableSlots,
  getAvailableSlotsSuccess,
  getAvailableSlotsError,
  resetBookingScheduleState,
} = bookingReschedule.actions;

export default bookingReschedule.reducer;
