import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  notificationList: [],
  isLoading: false,
  success: null,
  error: null,
};

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    getNotificationsList: (state) => {
      state.isLoading = true;
    },
    getNotificationsListSuccess: (state, action) => {
      state.isLoading = false;
      state.notificationList = action.payload;
    },
    getNotificationsListFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    // delete one notification
    deleteOneNotification: (state, action) => {
      state.isLoading = true;
    },
    deleteOneNotificationSuccess: (state, action) => {
      state.isLoading = false;
      state.success = action.payload;
    },
    deleteOneNotificationFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    // delete all
    deleteAllNotification: (state, action) => {
      state.isLoading = true;
    },
    deleteAllNotificationSuccess: (state, action) => {
      state.isLoading = false;
      state.success = action.payload;
    },
    deleteAllNotificationFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    // read one
    readOneNotifiaction: (state, action) => {
      state.isLoading = true;
    },
    readOneNotifiactionSuccess: (state, action) => {
      state.isLoading = false;
      state.success = action.payload;
    },
    readOneNotifiactionFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    // read All
    readAllNotifiaction: (state, action) => {
      state.isLoading = true;
    },
    readAllNotifiactionSuccess: (state, action) => {
      state.isLoading = false;
      // state.success = action.payload;
      state.success = null;
    },
    readAllNotifiactionFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    // reset states
    resetNotificationStates: (state, action) => {
      state.isLoading = false;
      state.success = null;
      state.error = null;
    },
  },
});

export const {
  getNotificationsList,
  getNotificationsListSuccess,
  getNotificationsListFailure,
  deleteOneNotification,
  deleteOneNotificationSuccess,
  deleteOneNotificationFailure,
  deleteAllNotification,
  deleteAllNotificationSuccess,
  deleteAllNotificationFailure,
  readOneNotifiaction,
  readOneNotifiactionSuccess,
  readOneNotifiactionFailure,
  readAllNotifiaction,
  readAllNotifiactionSuccess,
  readAllNotifiactionFailure,
  resetNotificationStates,
} = notificationSlice.actions;

export default notificationSlice.reducer;
