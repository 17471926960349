import { takeLatest, put } from 'redux-saga/effects';
import { $authHost } from '../axios';
import {
  getServiceSchedule,
  getServiceScheduleSuccess,
  getServiceScheduleError,
  // specialist booked schedule
  getSpecialistSchedule,
  getSpecialistScheduleSuccess,
  getSpecialistScheduleError,
  //
  updateServiceSchedule,
  updateServiceScheduleSuccess,
  updateServiceScheduleError,
  // booking
  updateBookingSchedule,
  updateBookingScheduleSuccess,
  updateBookingScheduleError,
  getAllBookingSchedules,
  getAllBookingSchedulesSuccess,
  getAllBookingSchedulesError,
  // availableServiceSlots
  getAvailableSlots,
  getAvailableSlotsSuccess,
  getAvailableSlotsError,
} from './bookingRescheduleSlice';

function* specialistBookedSchedule(action) {
  const { payload } = action;

  try {
    const response = yield $authHost.post(
      `/service-booking-schedule/specialist-booked-schedule`,
      payload,
    );
    yield put(getSpecialistScheduleSuccess(response?.data.data.specialistSchedules));
  } catch (error) {
    if (error.response?.status === 500) {
      const errorPreview = {
        code: 500,
        message: 'Internal Server Error',
        description: 'Internal Server Error',
      };
      yield put(getSpecialistScheduleError(errorPreview));
    } else if (error.response?.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      yield put(getSpecialistScheduleError(errorPreview));
    }
  }
}

function* serviceSchedule(action) {
  const { payload } = action;

  try {
    const response = yield $authHost.post(`/service-booking-schedule/service-schedule`, payload);
    yield put(getServiceScheduleSuccess(response?.data.data.serviceSchedule));
  } catch (error) {
    if (error.response?.status === 500) {
      const errorPreview = {
        code: 500,
        message: 'Internal Server Error',
        description: 'Internal Server Error',
      };
      yield put(getServiceScheduleError(errorPreview));
    } else if (error.response?.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      yield put(getServiceScheduleError(errorPreview));
    }
  }
}

function* serviceScheduleUpdate(action) {
  const { payload } = action;
  try {
    const response = yield $authHost.post(`/service-booking-schedule/reschedule-service`, payload);
    yield put(updateServiceScheduleSuccess(response?.data));
  } catch (error) {
    if (error.response?.status === 500) {
      const errorPreview = {
        code: 500,
        message: 'Internal Server Error',
        description: 'Internal Server Error',
      };
      yield put(updateServiceScheduleError(errorPreview));
    } else if (error.response?.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      yield put(updateServiceScheduleError(errorPreview));
    }
  }
}

function* updateBookingSchedules(action) {
  const { payload } = action;

  try {
    const response = yield $authHost.post(`/service-booking-schedule/reschedule-booking`, payload);
    yield put(updateBookingScheduleSuccess(response?.data));
  } catch (error) {
    if (error.response?.status === 500) {
      const errorPreview = {
        code: 500,
        message: 'Internal Server Error',
        description: 'Internal Server Error',
      };
      yield put(updateBookingScheduleError(errorPreview));
    } else if (error.response?.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      yield put(updateBookingScheduleError(errorPreview));
    }
  }
}

function* fetchAllBookingSchedules() {
  try {
    const response = yield $authHost.get(`/service-booking-schedule/get-all`);

    if (response.status === 200) {
      yield put(getAllBookingSchedulesSuccess(response.data.data.bookingSchedules));
    }
  } catch (error) {
    if (error.response?.status === 500) {
      const errorPreview = {
        code: 500,
        message: 'Internal Server Error',
        description: 'Internal Server Error',
      };
      yield put(getAllBookingSchedulesError(errorPreview));
    } else if (error.response?.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      yield put(getAllBookingSchedulesError(errorPreview));
    }
  }
}

function* availableServiceSlotsSaga(action) {
  const { payload } = action;

  try {
    const response = yield $authHost.post(`/service-booking-schedule/available-slots`, payload);
    yield put(getAvailableSlotsSuccess(response?.data?.data?.slots));
  } catch (error) {
    if (error.response?.status === 500) {
      const errorPreview = {
        code: 500,
        message: 'Internal Server Error',
        description: 'Internal Server Error',
      };
      yield put(getAvailableSlotsError(errorPreview));
    } else if (error.response?.data) {
      const { code, message, description } = error.response.data;
      const errorPreview = { code, message, description };
      yield put(getAvailableSlotsError(errorPreview));
    }
  }
}

export default function* bookingScheduleSaga() {
  yield takeLatest(getServiceSchedule.type, serviceSchedule);
  yield takeLatest(getSpecialistSchedule.type, specialistBookedSchedule);
  //
  yield takeLatest(updateServiceSchedule.type, serviceScheduleUpdate);
  yield takeLatest(updateBookingSchedule.type, updateBookingSchedules);
  yield takeLatest(getAllBookingSchedules.type, fetchAllBookingSchedules);
  yield takeLatest(getAvailableSlots.type, availableServiceSlotsSaga);
}
